body{
    overflow-x: hidden;
}

.works_table{
    border-radius: 20px;
    border: 2px solid white;
    width: 95%;
    height: 95%;


}
.folder-img{
    width: 75px;
    height: 75px;

}
.works_main{
    width: 100vw;
    height: 100vh;
    padding: 5vw;
}
.files_main{
    padding: 2rem;
}

.link{
    color: aqua;
    font-size: x-large;
}